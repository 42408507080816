<template>
  <span class="pe-3" v-if="value?.id" v-on:click="pairing">
    <img :src="value.logo" v-if="value.logo" class="logo-image" />
    <span :class="{ 'ms-2': value.logo }">
      {{ value.name }}
    </span>
  </span>
  <span class="pe-3" v-else>
    <button
      class="btn btn-secondary btn-sm"
      :title="$t(`crmPaymentPairing.pairingPaymentToPartner`)"
      v-on:click="pairing"
    >
      <BaseIcon name="user-search" />
    </button>
  </span>
</template>
<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "CrmPaymentPairing",
  components: { BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    extraRow: Number,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    pairing() {
      window.apps.callEvent("pair:crm_payment", this.id, this.extraRow);
      this.$emit("pair");
    },
  },
  emits: ["update:modelValue", "pair"],
};
</script>
